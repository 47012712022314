import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0841d940"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "icons-container"
};
var _hoisted_2 = {
  "class": "grid"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  "class": "icon-item"
};
var _hoisted_5 = {
  "class": "grid"
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  "class": "icon-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  var _component_el_scrollbar = _resolveComponent("el-scrollbar");
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");
  var _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
    type: "border-card"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_tab_pane, {
        label: "Icons"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_scrollbar, {
            height: "100%"
          }, {
            "default": _withCtx(function () {
              return [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.svgIcons, function (item) {
                return _openBlock(), _createElementBlock("div", {
                  key: item,
                  onClick: function onClick($event) {
                    return $setup.handleClipboard($setup.generateIconCode(item), $event);
                  }
                }, [_createVNode(_component_el_tooltip, {
                  placement: "top"
                }, {
                  content: _withCtx(function () {
                    return [_createTextVNode(_toDisplayString($setup.generateIconCode(item)), 1 /* TEXT */)];
                  }),
                  "default": _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_4, [_createVNode($setup["DeepSvgIcon"], {
                      name: item,
                      style: {
                        "pointer-events": "none"
                      }
                    }, null, 8 /* PROPS */, ["name"]), _createElementVNode("span", null, _toDisplayString(item), 1 /* TEXT */)])];
                  }),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)], 8 /* PROPS */, _hoisted_3);
              }), 128 /* KEYED_FRAGMENT */))])];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_tab_pane, {
        label: "Element-UI Icons"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_scrollbar, {
            height: "100%"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(" 已经作废 "), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.elementIcons, function (item) {
                return _openBlock(), _createElementBlock("div", {
                  key: item,
                  onClick: function onClick($event) {
                    return $setup.handleClipboard($setup.generateElementIconCode(item), $event);
                  }
                }, [_createVNode(_component_el_tooltip, {
                  placement: "top"
                }, {
                  content: _withCtx(function () {
                    return [_createTextVNode(_toDisplayString($setup.generateElementIconCode(item)), 1 /* TEXT */)];
                  }),
                  "default": _withCtx(function () {
                    return [_createElementVNode("div", _hoisted_7, [_createElementVNode("i", {
                      "class": _normalizeClass('el-icon-' + item)
                    }, null, 2 /* CLASS */), _createElementVNode("span", null, _toDisplayString(item), 1 /* TEXT */)])];
                  }),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)], 8 /* PROPS */, _hoisted_6);
              }), 128 /* KEYED_FRAGMENT */))])];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })]);
}