import { createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["DeepButton"], {
    onClick: $setup.handleNoPassWordLogin
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("免密登录测试")];
    }),
    _: 1 /* STABLE */
  });
}