import { createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-bfb647b8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  style: {
    "width": "100%",
    "height": "100%",
    "padding": "2rem"
  },
  ref: "containerRef"
};
var _hoisted_2 = {
  "class": "headers"
};
var _hoisted_3 = {
  "class": "buttons"
};
var _hoisted_4 = {
  style: {
    "width": "100%",
    "display": "flex",
    "align-items": "center",
    "justify-content": "center"
  }
};
var _hoisted_5 = {
  "class": "operation"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  var _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$setup.checkedAmount.length >= 1 ? (_openBlock(), _createBlock($setup["DeepButton"], {
    key: 0,
    onClick: $setup.handleDownloads,
    type: "primary",
    size: "small"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode("批量下载 ")];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $setup.checkedAmount.length >= 1 && $setup.canControl ? (_openBlock(), _createBlock($setup["DeepButton"], {
    key: 1,
    onClick: $setup.handleDeleteAll,
    type: "danger",
    size: "small"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" 批量删除 ")];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)])]), _createVNode(_component_el_table, {
    "empty-text": "暂无数据",
    data: $setup.assignmentsDocuments,
    stripe: "",
    height: "100%",
    onCellClick: $setup.handleSee,
    onSelect: $setup.handleSelect,
    onSelectAll: $setup.handleSelect,
    "class": "assignments-documents"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        align: "center",
        type: "selection",
        width: "55"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        label: "缩略图"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_4, [_createVNode($setup["DeepMediaFileItem"], {
            file: {
              extType: scope.row.extType,
              fileObjectKey: scope.row.fileObjectKey
            }
          }, null, 8 /* PROPS */, ["file"])])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "documentTitle",
        label: "名称"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "documentSize",
        label: "大小"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "documentExt",
        label: "格式"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        prop: "createTime",
        label: "创建时间"
      }), _createVNode(_component_el_table_column, {
        align: "center",
        width: "280",
        label: "操作"
      }, {
        "default": _withCtx(function (scope) {
          return [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_tooltip, {
            content: "下载"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                "class": "icon",
                name: "svg-download",
                onClick: _withModifiers(function ($event) {
                  return $setup.handleDownload(scope.row);
                }, ["stop"])
              }, null, 8 /* PROPS */, ["onClick"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */), $setup.canControl ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            content: "删除"
          }, {
            "default": _withCtx(function () {
              return [_createVNode($setup["DeepSvgIcon"], {
                "class": "icon",
                name: "svg-trash",
                onClick: _withModifiers(function ($event) {
                  return $setup.handleDelete(scope.row);
                }, ["stop"])
              }, null, 8 /* PROPS */, ["onClick"])];
            }),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)) : _createCommentVNode("v-if", true)])];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"])], 512 /* NEED_PATCH */);
}