import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3cb63ec5"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "dock-media",
  ref: "containerRef"
};
var _hoisted_2 = {
  key: 0,
  "class": "assignments-panel-media-dock-task-detail"
};
var _hoisted_3 = {
  "class": "headers"
};
var _hoisted_4 = {
  style: {
    "flex": "1",
    "margin-left": "1rem"
  }
};
var _hoisted_5 = {
  style: {
    "width": "100%",
    "display": "flex",
    "align-items": "center",
    "justify-content": "center"
  }
};
var _hoisted_6 = {
  "class": "operation"
};
var _hoisted_7 = {
  key: 1,
  "class": "assignments-panel-media-dock-task-list"
};
var _hoisted_8 = {
  "class": "thumb"
};
var _hoisted_9 = {
  "class": "text"
};
var _hoisted_10 = {
  key: 1,
  "class": "no-admin"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  var _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_Transition, {
    name: "slide-fade-transform",
    mode: "out-in"
  }, {
    "default": _withCtx(function () {
      return [$setup.currentTask ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepButton"], {
        size: "small",
        onClick: $setup.handleGoBack
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("返回")];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("div", _hoisted_4, _toDisplayString($setup.currentTask.taskName), 1 /* TEXT */), $setup.checkedAmount.length >= 1 ? (_openBlock(), _createBlock($setup["DeepButton"], {
        key: 0,
        onClick: $setup.handleDownloads,
        type: "primary",
        size: "small"
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("批量下载 ")];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), $setup.checkedAmount.length >= 1 && $setup.canControl ? (_openBlock(), _createBlock($setup["DeepButton"], {
        key: 1,
        onClick: $setup.handleDeleteAll,
        type: "danger",
        size: "small"
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("批量删除 ")];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)]), _createVNode(_component_el_table, {
        data: $setup.taskFileList,
        stripe: "",
        fit: "",
        "empty-text": "暂无数据",
        height: "100%",
        onCellClick: $setup.handleSee,
        onSelect: $setup.handleSelect,
        onSelectAll: $setup.handleSelect,
        "class": "table-header"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_table_column, {
            align: "center",
            type: "selection",
            width: "55"
          }), _createVNode(_component_el_table_column, {
            align: "center",
            label: "缩略图"
          }, {
            "default": _withCtx(function (scope) {
              return [_createElementVNode("div", _hoisted_5, [_createVNode($setup["DeepMediaFileItem"], {
                file: {
                  extType: scope.row.extType,
                  fileObjectKey: scope.row.fileObjectKey
                }
              }, null, 8 /* PROPS */, ["file"])])];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_table_column, {
            align: "center",
            prop: "fileName",
            label: "名称"
          }), _createVNode(_component_el_table_column, {
            align: "center",
            prop: "filePayloadEnumDesc",
            label: "拍摄负载"
          }), _createVNode(_component_el_table_column, {
            prop: "fileSizeFormat",
            label: "大小"
          }), _createVNode(_component_el_table_column, {
            prop: "fileExt",
            label: "格式"
          }), _createVNode(_component_el_table_column, {
            prop: "createTimeFormat",
            label: "创建时间"
          }), _createVNode(_component_el_table_column, {
            width: "280",
            label: "操作"
          }, {
            "default": _withCtx(function (scope) {
              var _a;
              return [_createElementVNode("div", _hoisted_6, [_createCommentVNode("              <div class=\"look\">"), _createCommentVNode("                <span></span>"), $setup.canControl && scope.row.detectedFiles && ((_a = scope.row.detectedFiles) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 0,
                content: "查看"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode($setup["DeepSvgIcon"], {
                    "class": "icon",
                    name: "fault",
                    onClick: _withModifiers(function ($event) {
                      return $setup.handleSees(scope.row);
                    }, ["stop"])
                  }, null, 8 /* PROPS */, ["onClick"])];
                }),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)) : _createCommentVNode("v-if", true), _createCommentVNode("              </div>"), $setup.canControl ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 1,
                content: "编辑"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode($setup["DeepSvgIcon"], {
                    "class": "icon",
                    name: "edit",
                    onClick: _withModifiers(function ($event) {
                      return $setup.handleEdit(scope.row.fileId);
                    }, ["stop"])
                  }, null, 8 /* PROPS */, ["onClick"])];
                }),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)) : _createCommentVNode("v-if", true), _createVNode(_component_el_tooltip, {
                content: "下载"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode($setup["DeepSvgIcon"], {
                    "class": "icon",
                    name: "svg-download",
                    onClick: _withModifiers(function ($event) {
                      return $setup.handleDownload(scope.row);
                    }, ["stop"])
                  }, null, 8 /* PROPS */, ["onClick"])];
                }),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */), $setup.canControl ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 2,
                content: "删除"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode($setup["DeepSvgIcon"], {
                    "class": "icon",
                    name: "svg-trash",
                    onClick: _withModifiers(function ($event) {
                      return $setup.handleDelete(scope.row.fileId);
                    }, ["stop"])
                  }, null, 8 /* PROPS */, ["onClick"])];
                }),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)) : _createCommentVNode("v-if", true)])];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"])])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [$setup.JlinkType.user.isAdmin ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: $setup.taskList,
        stripe: "",
        fit: "",
        height: "100%",
        onCellClick: $setup.handleInfo,
        "class": "table-header"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_table_column, {
            align: "center",
            label: "名称",
            width: "400"
          }, {
            "default": _withCtx(function (scope) {
              return [_createElementVNode("div", _hoisted_8, [scope.row.detected ? (_openBlock(), _createBlock($setup["DeepSvgIcon"], {
                key: 0,
                "class": "look-over",
                name: "discriminate"
              })) : _createCommentVNode("v-if", true), _createVNode($setup["DeepSvgIcon"], {
                "class": "icon",
                name: "documents"
              }), _createElementVNode("div", _hoisted_9, _toDisplayString(scope.row.taskName), 1 /* TEXT */)])];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_table_column, {
            align: "center",
            prop: "mediaCount",
            label: "文件总数"
          }), _createVNode(_component_el_table_column, {
            align: "center",
            prop: "uploadedCount",
            label: "上传数量"
          }, {
            "default": _withCtx(function (scope) {
              return [_createElementVNode("span", null, _toDisplayString(scope.row.uploadedCount), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_table_column, {
            align: "center",
            prop: "startTime",
            label: "创建时间"
          }, {
            "default": _withCtx(function (scope) {
              return [_createElementVNode("span", null, _toDisplayString($setup.JlinkUtils.date.parseTime(scope.row.startTime, '{y}-{m}-{d} {h}:{i}:{s}')), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"])) : (_openBlock(), _createElementBlock("div", _hoisted_10, " 您不是管理员，没有权限 "))]))];
    }),
    _: 1 /* STABLE */
  })], 512 /* NEED_PATCH */);
}