import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "display": "inline-flex",
    "position": "absolute",
    "z-index": "5000",
    "bottom": "0",
    "background": "#f2f2f2"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createCommentVNode("    {{FlightControlModule.controlPointsList}}"), _createCommentVNode("{{flyToProcess}}"), _createTextVNode(" " + _toDisplayString($setup.reactive2) + " ", 1 /* TEXT */), _createCommentVNode("    {{aircraftOsd}}")]);
}