import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1d1eeb0b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "document-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["AppLink"], {
    "class": "item_",
    to: "https://element-plus.gitee.io/#/zh-CN"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" element+ ")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["AppLink"], {
    "class": "item_",
    to: "https://zhuanlan.zhihu.com/p/34735529"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" element插件说明 ")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["AppLink"], {
    "class": "item_",
    to: "https://blog.csdn.net/qq_20802379/article/details/81436634"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" js-cookie ")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["AppLink"], {
    "class": "item_",
    to: "http://open.xmeye.net/zh/"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" 雄迈开放平台 ")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["AppLink"], {
    "class": "item_",
    to: "https://www.yuque.com/qiannianshiguangliubuxiashunjiandejiyi/pom0qm"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" vue资料 ")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["AppLink"], {
    "class": "item_",
    to: "https://www.jb51.net/article/191721.htm?tdsourcetag=s_pcqq_aiomsg"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" 何时/使用 Vue3 render 函数的教程详解 ")];
    }),
    _: 1 /* STABLE */
  }), _createVNode($setup["AppLink"], {
    "class": "item_",
    to: "https://echarts.apache.org/zh/index.html"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(" elCharts ")];
    }),
    _: 1 /* STABLE */
  })]);
}