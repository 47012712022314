import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-44347112"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "assignments-detail-media"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.drones, function (item) {
    return _openBlock(), _createBlock($setup["DeepCell"], {
      title: item.droneName,
      showDivider: "",
      backgroundLess: "",
      isLink: "",
      key: item.droneId,
      onClick: function onClick($event) {
        return $setup.handleMediaSelect(item);
      },
      "class": _normalizeClass($setup.mediaSelectId === item.droneId ? 'item-selected' : '')
    }, null, 8 /* PROPS */, ["title", "onClick", "class"]);
  }), 128 /* KEYED_FRAGMENT */)), $setup.assignmentsType ? (_openBlock(), _createBlock($setup["DeepCell"], {
    key: 0,
    title: "任务附件",
    showDivider: "",
    backgroundLess: "",
    isLink: "",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $setup.handleMediaSelect();
    }),
    "class": _normalizeClass(!$setup.mediaSelectId ? 'item-selected' : '')
  }, null, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true)]);
}